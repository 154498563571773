<template>
  <div class="flex justify-between px-4 pb-4">
    <div class="flex">
      <div
        v-show="item.image"
        class="h-16 w-16 rounded-lg overflow-hidden bg-gray-100"
      >
        <img v-lazy="item.image" class="object-cover h-full w-full inset-0" />
      </div>
      <div
        v-show="!item.image"
        class="h-16 w-16 rounded-lg overflow-hidden bg-gray-100 flex items-center justify-center"
      >
        <div>
          <b-icon name="shopping-cart" class="text-gray-300 block" size="lg" />
        </div>
      </div>
      <div class="px-4 flex flex-col justify-between">
        <p class="item-name overflow-ellipsis font-semibold">{{ item.name }}</p>
        <div class="flex items-center">
          <button
            @click.stop="changeItemAmount(-1)"
            class="text-center px-2 py-0 border border-gray-300 rounded-full shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-8 h-8"
          >
            <b-icon name="minus" size="sm" />
          </button>
          <div class="mx-2">
            {{ item.amount }}
          </div>
          <button
            @click.stop="changeItemAmount(1)"
            class="text-center px-2 py-0 border border-gray-300 rounded-full shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-8 h-8"
          >
            <b-icon name="plus" size="sm" />
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-end justify-between">
      <div @click="removeFromCart" class="cursor-pointer">
        <b-icon name="trash-alt" size="sm" />
      </div>
      <div>
        {{ formattedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "../BIcon";
import money from "@/utils/money";

export default {
  name: "ShoppingCartItem",

  components: {
    BIcon
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      count: 1
    };
  },

  computed: {
    price() {
      return this.item.price;
    },
    formattedPrice() {
      return money.format(this.price);
    }
  },

  methods: {
    changeItemAmount(amountChange) {
      this.$store.dispatch("checkout/changeItemAmount", {
        item: this.item,
        amountChange
      });
    },
    removeFromCart() {
      this.$store.dispatch("checkout/removeFromCart", this.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-name {
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 375px) {
  .item-name {
    max-width: 18ch;
  }
}

@media (min-width: 414px) {
  .item-name {
    max-width: 22ch;
  }
}

@media (min-width: 450px) {
  .item-name {
    max-width: 27ch;
  }
}

@media (min-width: 640px) {
  .item-name {
    max-width: 15ch;
  }
}
</style>
