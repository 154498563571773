<template>
  <div
    class="content-card overflow-hidden bg-white rounded shadow flex flex-grow flex-col text-gray-800 text-left h-full"
    :class="{ detail: type === 'detail' }"
  >
    <div v-if="imageValues.length">
      <b-slideshow :images="imageValues" :title="title.value" />
    </div>
    <div class="h-full p-6 flex flex-col justify-between">
      <div class="whitespace-pre-wrap">
        <p class="font-semibold text-2xl">{{ title.value }}</p>
        <p class="text-base text-gray-700">{{ description.value }}</p>
      </div>
      <div>
        <a
          v-if="caption.value && caption.type === fieldTypes.URL"
          @click.stop
          :href="caption.value"
          target="_blank"
          class="block mt-4 text-blue-500 hover:text-blue-800"
        >
          Visit
          <b-icon class="ml-2" name="external-link-alt" />
        </a>
        <p v-else-if="caption.value" class="mt-4 text-gray-600 text-sm">
          {{ caption.value }}
        </p>
        <ul v-if="customFields.length" class="mt-4">
          <li
            v-for="(field, index) in customFields"
            :key="index"
            class="flex justify-between"
          >
            <span class="font-bold">{{ field.name }}</span>
            <a
              v-if="
                field.value &&
                  (field.type === fieldTypes.URL ||
                    field.type === fieldTypes.IMAGE)
              "
              @click.stop
              :href="field.value"
              target="_blank"
              class="text-blue-500 hover:text-blue-800"
            >
              <b-icon class="ml-2" name="external-link-alt" />
            </a>
            <span v-else class="text-right">{{ field.value }}</span>
          </li>
        </ul>
        <button
          v-if="isCheckoutAction && ctaValue"
          @click.self.prevent="addToCart"
          class="w-full mt-4 bg-blue-500 hover:bg-blue-700 font-medium py-2 px-4 rounded text-center hover:shadow-md transition-shadow duration-300 focus:outline-none"
          :style="{
            'background-color': this.cta.bgColor,
            color: this.cta.color
          }"
        >
          {{ cta.text }}
        </button>
        <a
          v-else-if="ctaValue"
          @click.stop
          class="block mt-4 bg-blue-500 hover:bg-blue-700 font-medium py-2 px-4 rounded text-center hover:shadow-md transition-shadow duration-300"
          :href="ctaValue"
          :style="{
            'background-color': this.cta.bgColor,
            color: this.cta.color
          }"
          :target="cta.download && cta.value ? '_self' : '_blank'"
        >
          {{ cta.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "./BIcon";
import BSlideshow from "./BSlideshow";
import ActionTypes from "@/constants/action-types.js";
import FieldTypes from "@/constants/field-types";
import StringUtils from "@/utils/string";

export default {
  name: "BContentCard",

  components: {
    BIcon,
    BSlideshow
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "list",
      validator: val => ["list", "detail"].indexOf(val) > -1
    }
  },

  data() {
    return {
      fieldTypes: FieldTypes
    };
  },

  computed: {
    cta() {
      return {
        text: this.config?.cta?.text || "",
        value: this.item[this.config?.cta?.field?.name] || "",
        bgColor: this.config?.cta?.bgColor || "#3398db",
        color: this.config?.cta?.color || "#ffffff",
        download: this.config?.cta?.download || false,
        type: this.config?.cta?.type || ActionTypes.NUM_LINK
      };
    },
    ctaValue() {
      if (this.cta.download && this.cta.value) {
        return StringUtils.directDownloadLink(this.cta.value);
      }
      return this.cta.value;
    },
    title() {
      return {
        value: this.item[this.config?.title?.name] || "",
        type: this.config?.title?.type || ""
      };
    },
    description() {
      return {
        value: this.item[this.config?.description.name] || "",
        type: this.config?.description?.type || ""
      };
    },
    image() {
      const image = this.config?.image;
      return {
        value: Array.isArray(image.name)
          ? image.name || []
          : [image.name] || [],
        type: image.type || ""
      };
    },
    imageValues() {
      return this.image.value.reduce((arr, el) => {
        const value = this.item[el];
        if (value) {
          const link = StringUtils.directDownloadLink(value) || value;
          arr.push(link);
        }
        return arr;
      }, []);
    },
    caption() {
      return {
        value: this.item[this.config?.caption?.name] || "",
        type: this.config?.caption?.type || ""
      };
    },
    customFields() {
      return (
        this.config?.custom
          ?.map(el => ({
            name: el.name,
            value: this.item[el.name],
            type: el.type || ""
          }))
          .filter(el => el.value) || []
      );
    },
    isCheckoutAction() {
      return this.cta?.type === ActionTypes.NUM_CHECKOUT;
    }
  },

  methods: {
    addToCart() {
      this.$store.dispatch("checkout/addToCart", this.item);
    },
    visitLink(link) {
      window.open(link);
    }
  }
};
</script>
