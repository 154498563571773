<template>
  <footer class="bg-white">
    <div class="container mx-auto px-4 py-6 text-center text-gray-900">
      <p>
        {{ $t("this_website_was_built_using") }}
        <strong>
          <a href="https://getbiew.com/">Biew</a>
        </strong>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",

  computed: {
    lang() {
      return this.$store.state.config.lang;
    }
  }
};
</script>
