import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BButton from "@/components/BButton";
import "./styles/vendor/tailwind/index.css";
import "./styles/index.scss";
import "./plugins";
import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.component("b-button", BButton);

if (process.env.VUE_APP_MODE === "widget") {
  Vue.use(vueCustomElement);
  App.store = store;
  Vue.customElement("biew-widget", App);
} else {
  store.dispatch("setConfig");
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#biew-app");
}
