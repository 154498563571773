<template>
  <a
    :href="`https://getbiew.com?ref=${websiteHost}`"
    class="biew-badge fixed px-4 py-2 bg-gray-900 text-white font-light shadow-lg"
  >
    {{ $t("made_with") }}
    <span class="font-bold">Biew</span>
  </a>
</template>

<script>
export default {
  name: "BiewBadge",

  computed: {
    lang() {
      return this.$store.state.config.lang;
    },
    websiteHost() {
      return document.location.host;
    }
  }
};
</script>

<style lang="scss" scoped>
.biew-badge {
  right: 1em;
  bottom: 1em;
  border-radius: 1em;
  transition: all 0.3s ease;
}

.biew-badge:hover {
  bottom: 1.5em;
}
</style>
