<template>
  <div class="layout-main" :class="{ 'bg-gray-100': !isWidget }">
    <the-navbar v-if="!isWidget" />
    <div class="router-wrapper">
      <router-view />
    </div>
    <the-footer v-if="showBrandedFooter" />
    <biew-badge v-if="showBiewBadge" />
    <shopping-cart v-if="showShoppingCart" />
  </div>
</template>

<script>
import BiewBadge from "@/components/BiewBadge";
import ShoppingCart from "@/components/ShoppingCart/ShoppingCart";
import TheFooter from "@/components/TheFooter";
import TheNavbar from "@/components/TheNavbar";

export default {
  name: "Main",

  components: {
    BiewBadge,
    ShoppingCart,
    TheFooter,
    TheNavbar
  },

  computed: {
    isPro() {
      return this.$store.state.config.isPro;
    },
    isWidget() {
      return process.env.VUE_APP_MODE === "widget";
    },
    showBiewBadge() {
      return !this.isPro || this.showBranding;
    },
    showBranding() {
      return this.$store.state.config.showBranding;
    },
    showBrandedFooter() {
      return (
        (!this.isPro && !this.isWidget) || (!this.isWidget && this.showBranding)
      );
    },
    showShoppingCart() {
      return this.$store.getters["isShoppingCartEnabled"];
    }
  }
};
</script>

<style lang="scss" scoped>
#biew-app {
  .router-wrapper {
    box-sizing: border-box;
    min-height: 93vh;
  }
}
</style>
